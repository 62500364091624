/* Button Hover Effects */
.btn-primary {
    background-color: #007bff; /* Original blue */
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3; /* Darker blue for hover effect */
}

.btn-success {
    background-color: #28a745; /* Original green */
    border: none;
}

.btn-success:hover {
    background-color: #218838; /* Darker green for hover effect */
}

.btn-warning {
    background-color: #ffc107; /* Original yellow */
    border: none;
}

.btn-warning:hover {
    background-color: #e0a800; /* Darker yellow for hover effect */
}

/* Highlight Most Popular Plan */
.popular-plan {
    border: 2px solid #ffc107;
    background-color: #fffbe6; /* Light yellow background */
}
.popular-plan .card-title {
    position: relative;
    margin-bottom: 1rem; /* Add some margin to create space */
}

.popular-plan .card-title::after {
    content: 'Most Popular';
    color: white;
    background-color: #ffc107;
    font-size: 0.75rem;
    padding: 2px 8px;
    position: absolute;
    top: -20px; /* Move it slightly higher */
    right: -10px; /* Adjust position to the right */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a shadow for better visibility */
}

/* Card Shadow Effects */
.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
