/* Custom Navbar Styles */
.custom-navbar {
    background-color: #000000;
    padding: 0.2rem 1rem; /* Compact padding */
    min-height: 60px; /* Adjust the height of the navbar */
    border-bottom: 1px solid #d4af37; /* Add a subtle gold line at the bottom */
}

.custom-navbar .navbar-brand {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
}

.custom-navbar .navbar-brand::after {
    content: '';
    display: block;
    width: 1px; /* Thinner line */
    height: 126%; /* Full height of the navbar */
    background-color: #d4af37; /* Gold color */
    margin-left: 15px; /* Space between logo and line */
    position: absolute;
    right: -20px; /* Adjust the position of the line */
}

.custom-navbar .navbar-brand img {
    width: 40px; /* Adjust the logo size */
    margin-right: 8px;
    cursor: pointer; /* Make the logo look clickable */
}

.custom-navbar .navbar-nav .nav-link {
    color: #fff;
    margin-right: 20px; /* Space between links */
    padding: 0.5rem 0.5rem; /* Adjust padding */
    text-transform: uppercase; /* Make text uppercase to match the style */
}

.custom-navbar .navbar-nav .nav-link:hover {
    color: #f0ad4e;
}

.custom-navbar .navbar-nav .nav-link.active-link {
    font-weight: bold;
    color: #f0ad4e;
}

.custom-navbar .navbar-toggler {
    padding: 0.25rem 0.75rem; /* Adjust padding */
}

/* User Dropdown Toggle */
.custom-navbar .username-dropdown-toggle {
    color: white !important; /* Set the username color to white */
    text-decoration: none; /* Remove underline */
    font-weight: bold;
}

.custom-navbar .username-dropdown-toggle:hover,
.custom-navbar .username-dropdown-toggle:focus,
.custom-navbar .username-dropdown-toggle:active,
.custom-navbar .username-dropdown-toggle[aria-expanded="true"] {
    color: #f0ad4e !important; /* Change color on hover/focus */
    text-decoration: none !important; /* Ensure underline is not added */
}

/* Adjust Button Styles */
button.rounded-pill {
    padding: 0.4rem 1rem; /* Adjust padding */
    background-color: #f0ad4e;
    border-color: #f0ad4e;
    color: #fff;
    border-radius: 25px; /* Make it a pill shape */
    font-weight: bold;
    margin-left: 10px; /* Space between buttons */
}

button.rounded-pill:hover {
    background: linear-gradient(90deg, #ffc107, #f0ad4e);
    border: none;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
    .navbar-nav .nav-link.active-link::after {
        width: 0;
    }
}


/* Existing .sidebar styles */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    padding: 20px;
    overflow-y: auto;
    transform: translateX(-100%); /* Hidden by default */
    transition: transform 0.3s ease-in-out;
}
  /* Sidebar visible state */
.sidebar-visible {
    transform: translateX(0); /* Slide in when open */
}
 /* Content overlay */
.content-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    display: none; /* Hidden by default */
    transition: all 0.3s ease-in-out;
}
  

 /* Show overlay when sidebar is visible */
.sidebar-open .content-overlay {
    display: block;
}

        /* Shift main content when sidebar is open */
.main-content {
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-open .main-content {
    margin-left: 250px; /* Match sidebar width */
} 


 /* Ensure sidebar is displayed on all screen sizes */
@media (min-width: 992px) {
    .sidebar {
        display: block;
        transform: translateX(-100%); /* Keep it hidden by default */
    }

    .content-overlay {
        display: none; /* Hide overlay by default */
    }

    .sidebar-open .main-content {
        margin-left: 250px; /* Adjust content margin for larger screens */
    }
}
  
/* Sidebar Links */
.sidebar .nav-link {
    color: black;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
}

.sidebar .nav-link:hover {
    background-color: #f0ad4e; /* Optional hover effect */
    color: white; /* Change text color on hover */
}

.sidebar .nav-link.active {
    font-weight: bold;
    color: #f0ad4e; /* Highlight active link */
}
